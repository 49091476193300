import React, { useMemo } from 'react';
import loadable from '@loadable/component';
import { BUTTON_MODAL, BUTTON_WHITE, MAIN_BUTTON_CLASSNAME, WHITE_BUTTON_CLASSNAME } from '../constants';

const DotsLoader = loadable(() => import("./Loader"))

function Button({ buttonText, variant, size, handleClick, submit, loading, ...rest }) {
  const buttonClassName = useMemo(() => {
    const extraClassName = (size === BUTTON_MODAL) ? "button__size-modal" : ""
    return variant === BUTTON_WHITE ? `${WHITE_BUTTON_CLASSNAME} ${extraClassName}` : `${MAIN_BUTTON_CLASSNAME} ${extraClassName}`;
  }, [variant]);

  return (
    <>
      {loading ? (
        <div 
          className="button__wrapper button__wrapper-loading"
          style={{...rest}}
        >
          <DotsLoader />
        </div>
      ) : (
        <div className="button__wrapper">
          {submit ? (
            <input 
              className={buttonClassName} 
              style={{ ...rest }} 
              onClick={handleClick}
              value={buttonText}
              type="submit"
            />
          ) : (
            <button 
              className={buttonClassName} 
              style={{ ...rest }} 
              onClick={handleClick}>
              {buttonText}
            </button>
          )}
        </div>
      )}
    </>
  );
}

export default Button;
