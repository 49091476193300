import React from 'react';
import { Link } from 'gatsby';

import { SecondaryDescription } from '../Typography';
import LinksContainer from './LinksContainer';
import { useLocation } from '@reach/router';
import { LINK_PREFIX } from '../../constants';

const Footer = ({connectText = "Connect"}) => {
  const location = useLocation()

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-left-side">
          <div className="footer__description-main">
            <SecondaryDescription>
              Sprive Limited is an appointed representative of {connectText} IFA Ltd for mortgage 
              services (<a href="https://register.fca.org.uk/s/firm?id=001b000000MfbXtAAJ" target="__blank">441505</a>) and Prepay 
              Technologies Ltd which is an electronic money institution authorised by the Financial 
              Conduct Authority under the Electronic Money Regulations 2011 (FRN <a href="https://register.fca.org.uk/s/firm?id=001b000000m4IWrAAM" target="__blank">900010</a>) for 
              the issuing of electronic money which are authorised and regulated by the Financial Conduct Authority. 
              Our Financial Services Register number is <a href="https://register.fca.org.uk/s/firm?id=0014G00002TDsdkQAD" target="__blank">919863</a>.
              <br/>
              <br/>
              The information contained within this website is subject to the UK regulatory regime and is therefore targeted at consumers based in the UK.
            </SecondaryDescription>
          </div>
          <p className="footer__description-bold">
            <SecondaryDescription>
              Your home may be repossessed if you do not keep up repayments on your mortgage.
            </SecondaryDescription>
          </p>
            <SecondaryDescription>
              Sprive Limited. Registered Address: Cannon Place, 78 Cannon Street, London, 
              United Kingdom, EC4N 6AF. Registered in England and Wales No:12102682
            </SecondaryDescription>
          {location?.pathname !== "/overpayment-calculator/" && (
            <>
              <div className="footer__description-container">
                <p className="footer__description">
                  <a href="https://www.about.hsbc.co.uk/-/media/uk/en/news-and-media/rbwm/151118-svr-homeowners-paying-4000-a-year-too-much.pdf" target="_blank" className="footer__description-decorated">* <span>HSBC study</span></a>
                  about homeowners on lender’s standard variable rate (SVR) mortgage.</p>
              </div>
              {!process.env.GATSBY_CURRENT_SUBDOMAIN && (
                <span className="footer__sub-text" >Savings illustrated for £3 a day towards the mortgage is based on a repayment mortgage with a balance of £250,000 at 5% interest for 25 years.</span>
              )}
            </>
          )}
        </div>
        <div className="footer-right-side">
          <div className="footer__links-container">
            <Link to={`${LINK_PREFIX}/about`} className="footer__description footer__description-regular">About us</Link>
            <Link to={`${LINK_PREFIX}/get-in-touch`} className="footer__description footer__description-regular">Get in Touch</Link>
            <Link to={`${LINK_PREFIX}/blog`} className="footer__description footer__description-regular">Blog</Link>
            <Link to={`${LINK_PREFIX}/#trust-and-security`} className="footer__description footer__description-regular">Trust & Security</Link>
            <Link to={`${LINK_PREFIX}/mortgages/remortgage`} className="footer__description footer__description-regular">Remortgage</Link>
            <Link to={`${LINK_PREFIX}/privacy`} className="footer__description footer__description-regular">Privacy Policy</Link>
            <Link to={`${LINK_PREFIX}/mortgages/overpayment-calculator`} className="footer__description footer__description-regular">Overpayment Calculator</Link>
            
            { process.env.GATSBY_CURRENT_SUBDOMAIN ?             
            <Link to={`${LINK_PREFIX}/mortgageleads-terms`} className="footer__description footer__description-regular">Terms and Conditions</Link>
            :
            <Link to={`${LINK_PREFIX}/terms`} className="footer__description footer__description-regular">Terms and Conditions</Link>}

            <Link to={`${LINK_PREFIX}/mortgages/cashback-calculator`} className="footer__description footer__description-regular">Shop with Sprive</Link>
            <Link to={`${LINK_PREFIX}/faq`} className="footer__description footer__description-regular">FAQ</Link>
          </div>

            <LinksContainer />
        </div>
      </div>
    </footer>
  )
};

export default Footer;
