import React, { useCallback, useState } from 'react'
import { Link } from 'gatsby';
import IconArrowDown from '../../assets/images/icons/other/arrow-down.inline.svg';

function DropdownItem({title, subItems, itemSelectedIndex, index, toggleHandle}) {
  const [selectedIndex, setSelectedIndex] = useState(null)

  const handleClick = useCallback((index) => {
    setSelectedIndex(index)
    toggleHandle(false)
  }, [toggleHandle])

  const generateSubItems = useCallback(() => {
    const components = []

    subItems.forEach((item, key) => {
        const isSelected = ((selectedIndex === key) && (itemSelectedIndex === index))

        components.push((
            <Link 
                key={item.url + key}
                to={item.url}
                onClick={() => handleClick(key)}
                className={ isSelected ? "active" : null }
            >
              {item.title}
            </Link>
        ))
    })
    return components
  }, [subItems, selectedIndex, itemSelectedIndex, index])

  return (
    <div className="dropdown-wrapper">
        <div className="text__container">
            <p>{title}</p>
            <IconArrowDown />
        </div>
        <div className="dropdown">
            <div className="dropdown-items__container">
                {generateSubItems()}
            </div>
        </div>
    </div>
  )
}

export default DropdownItem