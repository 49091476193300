import React, { useEffect } from 'react';
import { Link } from 'gatsby';

import IconCross from '../assets/images/icons/other/cross.inline.svg';

const CookieBanner = ({cookieAgree, setCookieAgree}) => {
  useEffect(() => {
    localStorage.getItem('cookie-agree') ? setCookieAgree(true) : setCookieAgree(false);
  }, []);

  const handleClick = () => {
    localStorage.setItem('cookie-agree', 'true');
    setCookieAgree(true);
  };

  return !cookieAgree ? (
    <div className="cookie-banner">
      <div className="wrapper">
        <p>
          This website uses cookies to ensure the best experience. By continuing to browse this
          website you are agreeing to our use of cookies. <Link to="/privacy">More information</Link>
        </p>
        <div onClick={handleClick} className="close-btn">
          <IconCross />
        </div>
      </div>
    </div>
  ) : null;
};

export default CookieBanner;
