import React from 'react';

import { ContextProviderComponent } from './Context';
import Header from './Header';
import Footer from './Footer/Footer';
import CookieBanner from './CookieBanner';
import { createContext } from 'react';
import { useState } from 'react';

export const LayoutContext = createContext(null)

const Layout = ({ children, uri }) => {
  const [cookieAgree, setCookieAgree] = useState(false);
  const [footerContent, setFooterContent] = useState("Connect")

  return (
    <>
      <ContextProviderComponent>
        <Header isMainPage={uri === '/'} /> 
        <LayoutContext.Provider value={{
          cookieAgree,
          setFooterContent
        }}>
          <main>{children}</main>
        </LayoutContext.Provider>
        <Footer connectText={footerContent} />
        <CookieBanner cookieAgree={cookieAgree} setCookieAgree={setCookieAgree}/>
      </ContextProviderComponent>
    </>
  );
};

export default Layout;
