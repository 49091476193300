import React, { useCallback } from 'react';
import { Link, navigate } from 'gatsby';
import useWindow from '../../hooks/useWindow';
import DropdownItem from './DropdownItem';
import { LINK_PREFIX } from '../../constants';
import { useState } from 'react';
import { useEffect } from 'react';

const menuItems = [
    {
        title: "Remortgage",
        url: `${LINK_PREFIX}/mortgages/remortgage`
    },
    {
        title: "Calculators",
        url: "/",
        isDropdown: true,
        subItems: [
            {
                title: "Overpayment Calculator",
                url: `${LINK_PREFIX}/mortgages/overpayment-calculator`
            },
            {
                title: "Shop with Sprive",
                url: `${LINK_PREFIX}/mortgages/cashback-calculator`
            }
        ]
    },
    {
        title: "About Us",
        url: `${LINK_PREFIX}/about`
    },
    {
        title: "Blogs",
        url: `${LINK_PREFIX}/blog`
    },
    {
        title: "Get In Touch",
        url: `${LINK_PREFIX}/get-in-touch`
    },
    {
        title: "FAQ",
        url: `${LINK_PREFIX}/faq`
    }
]

export const Menu = ({ toggleHandle, setIsDropDownOpen, selectedIndex, setSelectedIndex }) => {
    const { isTablet, isMobile } = useWindow()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [firstModalOpen, setFirstModalOpen] = useState(false)

    const handleClick = useCallback((index) => {
        toggleHandle(false)
        setSelectedIndex(index)
    }, [toggleHandle])

    const generateMenuItems = useCallback(() => {
        const components = []

        menuItems.forEach((item, key) => {
            const isSelected = selectedIndex === key
            components.push((
                <>
                {item?.isDropdown?(
                <li
                    key={item.title + key}
                    onClick={() => {
                        setIsDropDownOpen(prevState => !prevState)
                        setSelectedIndex(key)
                    }}
                    className={isSelected?"active": null}
                >
                    <DropdownItem
                        title={item.title}
                        subItems={item.subItems}
                        itemSelectedIndex={selectedIndex}
                        index={key}
                        toggleHandle={toggleHandle}
                    />
                </li>
                ): (
                    <li key={item.title + key}>
                        {process.env.GATSBY_CURRENT_SUBDOMAIN ? (
                            <a 
                                href={item.url}
                                target="_blank"
                                onClick={() => handleClick(key)}
                                className={isSelected?"active": null}
                                title={item.title}
                            >
                                {item.title}
                            </a>
                        ) : (
                            <Link 
                                to={item.url}
                                onClick={() => handleClick(key)}
                                className={isSelected?"active": null}
                                title={item.title}
                            >
                                {item.title}
                            </Link>
                        )}
                    </li>
                )}
                </>
            ))
        })

        return components
    }, [selectedIndex])

    useEffect(() => {
        if(isMobile && firstModalOpen) {
          if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
            navigate('https://play.google.com/store/apps/details?id=com.sprive')
          }
          if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
            navigate('https://apps.apple.com/us/app/sprive-mortgage-free-faster/id1504836142')
          }
        }
  
      if(isMobile && isModalOpen) {
        setFirstModalOpen(true)
        setIsModalOpen(false)
      }
    }, [isMobile, isModalOpen, firstModalOpen])

    return (
        <>
            {generateMenuItems()}
            {(isMobile || isTablet) && (
                <div className="download__container" onClick={() => setIsModalOpen(true)}>
                    <p>Download App</p>
                </div>
            )}
        </>
    )
};