import React from 'react';
import imagePlayStore from '../assets/images/badges/google-play-badge (2)@3x.svg';
import imageAppStore from '../assets/images/badges/download-on-the-app-store-apple (1)@3x.svg';
import useWindow from '../hooks/useWindow';

// const handlePlaystoreClick = event => {
//   trackCustomEvent({ category: "App store", action: "Click", label: "Android"});
// };

// const handleAppStoreClick = event => {
//   trackCustomEvent({ category: "App store", action: "Click", label: "IOS"});
// };

const Badges = () => {
  const { isMobile } = useWindow()

  return (
    <div>
      <a
        href={isMobile ? "https://play.google.com/store/apps/details?id=com.sprive" : "https://linkprod.sprive.com/fromSiteToPlayStore"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={imagePlayStore} alt="play-store" />
      </a>
      <a
        href={isMobile ? "https://apps.apple.com/us/app/sprive-mortgage-free-faster/id1504836142" : "https://linkprod.sprive.com/fromSiteToAppStore"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={imageAppStore} alt="app-store" />
      </a>
    </div>
  );
};

export default Badges;
